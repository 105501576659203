<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valueIndex) in values" :key="valueIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-card :title="$t('Navbar.Reports')">
      <b-row>
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group
            :label="$t('Product_Index.sortable')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-50"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-50"
              >
                <option :value="false">
                  {{ $t("Product_Index.ASC") }}
                </option>
                <option :value="true">
                  {{ $t("Product_Index.DESC") }}
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            :label="$t('Product_Index.Filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Product_Index.Search')"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  {{ $t("Product_Index.Search") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height:2.142rem; line-height:0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="removeExitem"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="BrandTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(user)="items">
              <b-badge
                size="lg"
                variant="primary"
                >Vendor: {{ items.item.vendor.storename }}</b-badge
              >
             
             
            </template>
            <template #cell(score)="items">
              %  {{ items.value }}  
             
            </template>

            <template #cell(actions)="items">
              <div class="demo-inline-spacing">
                <b-button
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  :to="{ path: '/diagnostic/reports/show/' + items.item.id }"
                  variant="success"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="EyeIcon"
                    style="width:18px; height:18px;"
                  />
                </b-button>
                <b-button v-if="($store.state.vendorData.is_portal || $store.state.vendorData.in_store || $store.state.vendorData.is_sell_used || $store.state.vendorData.is_sell_new)"
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  @click="deleteData(items.item.id)"
                  variant="danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width:18px; height:18px;"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body
        class="d-flex justify-content-between p-0 pagination-section"
      >
        <!-- page length -->
        <div class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("Product_Index.Per_Page")
          }}</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="BrandTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {

  data() {
    return {
      per: null,
      showDismissibleAlert: false,
      errors_back: [],
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      sortDirection: "desc",
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // table fields
      fields: [
        {
          key: "id",
          label: this.$t("Table_Head.ID"),
          // sortable: true
          sort_by: true,
        },
        {
          key: "user",
          label: this.$t("Table_Head.User/Vendor"),
        },
        {
          key: "type",
          label: this.$t("Table_Head.Type"),
        },
        {
          key: "variant_id",
          label: this.$t("Table_Head.Variant_ID"),
        },
        {
          key: "serial_number",
          label: this.$t("Table_Head.Serial_Number"),
        },

        {
          key: "score",
          label: this.$t("Table_Head.Score"),
        },
        {
          key: "score",
          label: "score",
        },
        {
          key: "phone_name",
          label: this.$t("Table_Head.Phone_Name"),
        },
        {
          key: "actions",
          label: this.$t("Table_Head.Actions"),
        },
      ],
      items: [],
      exportItems: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sort_by)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
  },
  watch: {
    sortDirection: function() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      this.fetchData();
    },
    sortBy: function() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      this.fetchData();
    },
    filter: function() {
      if (this.filter == "" || this.filter == null) {
        this.fetchData();
      }
    },
  },
  methods: {
    // fetch Data of Brands
    fetchData() {
      this.showDismissibleAlert = false;
      // request Brand index API
      var url = null;
      if (this.filter == "") {
        url =
          "diagnostic/reports-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "diagnostic/reports-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          const data = result.data.data.data;
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;
          if (data.length > 0) {
            this.items = data
            // for (let index in data) {
            //   if (data[index].user != null || data[index].vendor != null) {
            //     this.items.push({
            //       id: data[index].id,
            //       user:
            //         data[index].user != null
            //           ? "User: " +
            //             data[index].user.firstname +
            //             " " +
            //             data[index].user.lastname
            //           : "Vendor: " + data[index].vendor.storename,
            //       type: data[index].type,
            //       variant_id: data[index].variant_id,
            //       serial_number: data[index].serial_number,

            //       score: data[index].score + " %",
            //       phone_name: data[index].phone_name,
            //     });
            //   } else {
            //     this.items.push({
            //       id: data[index].id,
            //       user: "",
            //       type: data[index].type,
            //       variant_id: data[index].variant_id,
            //       serial_number: data[index].serial_number,

            //       score: data[index].score + " %",
            //       phone_name: data[index].phone_name,
            //     });
            //   }
            // }
          }
          
          this.exportItems = this.items;

          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    searchData() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      this.fetchData();
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    // delete function to delete Brand
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("يرجى تأكيد رغبتك في حذف التقرير", {
          title: "هل أنت متأكد؟",
          size: "sm",
          okVariant: "danger",
          okTitle: "نعم",
          cancelTitle: "لا!",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("diagnostic/reports/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].logo;
      }
    },
    genPDF() {
                const { jsPDF } = require("jspdf");
      const doc = new jsPDF();
      let print_body = [];
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].desc,
          this.items[index].active,
          this.items[index].created_at,
          this.items[index].updated_at,
        ];
      }
      doc.autoTable({
        head: [
          [
            "ID",
            "User",
            "Type",
            "Variant ID",
            "Serial Number",
            "Score",
            "Brand Name",
          ],
        ],
        body: print_body,
      });
      doc.save("table.pdf");
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style>
@media only screen and (max-width: 425px) {
  .pagination-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pagination-section div {
    margin: 10px;
  }
}
</style>
